import React from 'react';
import styled from 'styled-components';

import Icon from '../../../../design_system/display/icons/Icon';
import { TruncatedText } from '../../../../design_system/Triage/TruncatedText';
import { deprecated } from '../../../../styled/TypeSystem';

const StyledLink = styled.a`
  text-decoration: none;
`;

const ContentWrapper = styled.div`
  background-color: ${({ theme: { vars } }) => vars.foundationBase1};
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusLg};
  color: ${({ theme: { vars } }) => vars.textDefault};
  display: flex;
  align-items: center;
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerSm3}`};
  height: ${({ theme: { constants } }) => constants.heightLg};
  gap: ${({ theme: { constants } }) => constants.spacerSm2};
  font-weight: ${({ theme: { constants } }) => constants.fontSemibold};
  cursor: pointer;

  ${deprecated.fontMd2};

  /* Intentionally overriding line-height here as line-height from fontMd2 is too large and cuts the text off */
  line-height: normal;

  &:hover {
    background-color: ${({ theme: { vars } }) => vars.foundationBase2};
  }
`;

const FileName = styled.span`
  ${TruncatedText({})};
`;

type PreviewMode =
  | {
      isPreview: true;
      contentUrl?: never;
    }
  | {
      isPreview?: never;
      contentUrl: string;
    };

export type Props = {
  fileName: string;
} & PreviewMode;

const FileDownload = ({ contentUrl, fileName, isPreview }: Props) => {
  const content = (
    <ContentWrapper>
      <Icon name='file-download' />
      <FileName>{fileName}</FileName>
    </ContentWrapper>
  );

  return isPreview ? (
    content
  ) : (
    <StyledLink data-drag-handle href={contentUrl} rel='noreferrer' target='_blank'>
      {content}
    </StyledLink>
  );
};

export default FileDownload;
